<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          type="text"
          label="Name"
          v-model="record.Descr"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.WhsCode"
          item-text="WhsName"
          item-value="WhsCode"
          label="WareHouses"
          :items="WareHouses"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-if="Object.keys(this.Sublevel1).length"
          v-model="record.SL1Code"
          item-text="SLCode"
          :item-value="Sublevel1.SLCode"
          label="Sublevel-1 Code"
          :items="Sublevel1"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-if="Object.keys(this.Sublevel2).length"
          v-model="record.SL2Code"
          item-text="SLCode"
          :item-value="Sublevel2.SLCode"
          label="Sublevel-2 Code"
          :items="Sublevel2"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-if="Object.keys(this.Sublevel3).length"
          v-model="record.SL3Code"
          item-text="SLCode"
          :item-value="Sublevel3.SLCode"
          label="Sublevel-3 Code"
          :items="Sublevel3"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-if="Object.keys(this.Sublevel4).length"
          item-text="SLCode"
          outlined
          dense
          :item-value="Sublevel4.SLCode"
          v-model="record.SL4Code"
          label="Sublevel-4 Code"
          :items="Sublevel4"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-checkbox
          outlined
          dense
          v-model="record.Disabled"
          label="Disabled"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-checkbox
          outlined
          dense
          label="Receiving Bin Location"
          v-model="record.ReceiveBin"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          type="number"
          label="Minimum Qty"
          v-model="record.MinLevel"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          type="number"
          label="	Maximum Qty"
          v-model="record.MaxLevel"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="4">
        <v-textarea v-model="record.Descr" outlined dense label="Description">
        </v-textarea>
      </v-col> -->

      <v-col v-if="Object.keys(this.attr1).length" cols="3">
        <v-text-field
          outlined
          dense
          type="text"
          :label="this.attr1[0].DispName"
          v-model="record.Attr1Val"
          @input="handleInput(attr1[0])"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr2).length"
          outlined
          dense
          type="text"
          :label="this.attr2[0].DispName"
          @input="handleInput(attr2[0])"
          v-model="record.Attr2Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr3).length"
          outlined
          dense
          type="text"
          :label="this.attr3[0].DispName"
          @input="handleInput(attr3[0])"
          v-model="record.Attr3Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr4).length"
          outlined
          dense
          type="text"
          :label="this.attr4[0].DispName"
          @input="handleInput(attr4[0])"
          v-model="record.Attr4Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr5).length"
          outlined
          dense
          type="text"
          :label="this.attr5[0].DispName"
          @input="handleInput(attr5[0])"
          v-model="record.Attr5Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr6).length"
          outlined
          dense
          type="text"
          :label="this.attr6[0].DispName"
          @input="handleInput(attr6[0])"
          v-model="record.Attr6Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr7).length"
          outlined
          dense
          type="text"
          :label="this.attr7[0].DispName"
          @input="handleInput(attr7[0])"
          v-model="record.Attr7Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr8).length"
          outlined
          dense
          type="text"
          :label="this.attr8[0].DispName"
          @input="handleInput(attr8[0])"
          v-model="record.Attr8Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr9).length"
          outlined
          dense
          type="text"
          :label="this.attr9[0].DispName"
          @input="handleInput(attr9[0])"
          v-model="record.Attr9Val"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-if="Object.keys(this.attr10).length"
          outlined
          dense
          type="text"
          :label="this.attr10[0].DispName"
          @input="handleInput(attr10[0])"
          v-model="record.Attr10Val"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="accent" @click="sendData" :loading="loader">
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    record: {},
    loader: false,
    Attibutes: [],
    Attr: [],
    WareHouses: [],
    Sublevel1: [],
    Sublevel2: [],
    Sublevel3: [],
    Sublevel4: [],
    attr1: [],
    attr2: [],
    attr3: [],
    attr4: [],
    attr5: [],
    attr6: [],
    attr7: [],
    attr8: [],
    attr9: [],
    attr10: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    handleInput(val) {
      if (val.FldNum == 105) {
        this.record.Attr1Abs = val.id;
      }
      if (val.FldNum == 106) {
        this.record.Attr2Abs = val.id;
      }
      if (val.FldNum == 107) {
        this.record.Attr3Abs = val.id;
      }
      if (val.FldNum == 108) {
        this.record.Attr4Abs = val.id;
      }
      if (val.FldNum == 109) {
        this.record.Attr5Abs = val.id;
      }
      if (val.FldNum == 110) {
        this.record.Attr6Abs = val.id;
      }
      if (val.FldNum == 111) {
        this.record.Attr7Abs = val.id;
      }
      if (val.FldNum == 112) {
        this.record.Attr8Abs = val.id;
      }
      if (val.FldNum == 113) {
        this.record.Attr9Abs = val.id;
      }
      if (val.FldNum == 114) {
        this.record.Attr10Abs = val.id;
      }
    },
    getAttributesData() {
      const self = this;
      this.$store
        .dispatch("get", `/bin-locations/fields?FieldType=A`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            let data = res.ResponseData;
            self.attr1 = data.filter((attrs) => {
              return attrs.FldNum === 105;
            });
            self.attr2 = data.filter((attrs) => {
              return attrs.FldNum === 106;
            });
            self.attr3 = data.filter((attrs) => {
              return attrs.FldNum === 107;
            });
            self.attr4 = data.filter((attrs) => {
              return attrs.FldNum === 108;
            });
            self.attr5 = data.filter((attrs) => {
              return attrs.FldNum === 109;
            });
            self.attr6 = data.filter((attrs) => {
              return attrs.FldNum === 110;
            });
            self.attr7 = data.filter((attrs) => {
              return attrs.FldNum === 111;
            });
            self.attr8 = data.filter((attrs) => {
              return attrs.FldNum === 112;
            });
            self.attr9 = data.filter((attrs) => {
              return attrs.FldNum === 113;
            });
            self.attr10 = data.filter((attrs) => {
              return attrs.FldNum === 114;
            });
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getWareHouses() {
      const self = this;
      this.$store
        .dispatch("get", `/warehouse?binActive=1`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.WareHouses = res.ResponseData;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSubLevels() {
      const self = this;
      this.$store
        .dispatch("get", `/bin-locations/sublevel_code`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            let data = res.ResponseData;
            self.Sublevel1 = data.filter((warehouse) => {
              return warehouse.bin_field.FldNum === 101;
            });

            self.Sublevel2 = data.filter((warehouse) => {
              return warehouse.bin_field.FldNum === 102;
            });

            self.Sublevel3 = data.filter((warehouse) => {
              return warehouse.bin_field.FldNum === 103;
            });
            self.Sublevel4 = data.filter((warehouse) => {
              return warehouse.bin_field.FldNum === 104;
            });
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      // this.loader = true;
      this.$emit("data", this.record);
    },
  },
  created() {
    this.getAttributesData();
    this.getWareHouses();
    this.getSubLevels();
  },
};
</script>
<style >
.hidden-element {
  display: none;
}
</style>